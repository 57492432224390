:root {
  --padding: 8px;
  --font-size: 14px;
}

@media screen and (min-width: 600px) {
  :root {
    --font-size: 16px;
  }
}

@media screen and (min-width: 700px) {
  :root {
    --font-size: 20px;
    --padding: 10px;
  }
}

@media screen and (min-width: 800px) {
  :root {
    --font-size: 24px;
  }
}

* {
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0D4296;
  background-color: rgba(0, 0, 0, 0.05);
  height: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: inherit;
}