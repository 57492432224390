.DataRow {
  margin-bottom: 6px;
  font-size: 18px;
}

.DataRow input {
  margin-right: 16px;
}

.DataRow--checked {
  color: #666;
  text-decoration: line-through;
}