.Tile {
  width: calc(25% - var(--padding));
  aspect-ratio: 1 / 1;
  border: 1px solid #357ded;
  border-radius: 4px;
  padding: var(--padding);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size);
  text-align: center;
  cursor: pointer;
  hyphens: auto;
}

.Tile--checked {
  background-color: #C7DAFA;
}