.Bingo {
  margin: 0 0 16px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--padding);
  justify-content: space-between;
}

.Bingo__list {
  margin-right: auto;
}

.Bingo__tiles {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  min-width: 300px;
  gap: var(--padding);
}

.Bingo__win {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.Bingo__win>div {
  transform: rotate(-15deg);
  font-weight: 900;
  color: #B24C63;
  letter-spacing: 20px;
  font-size: 80px;
  display: flex;
  flex-direction: column;
}

.Bingo__win small {
  font-size: 22px;
  color: #0D4296;
  text-align: center;
  letter-spacing: normal;
}