.App {
  max-width: 800px;
  margin: 0 auto;
  height: inherit;
}

.App__content {
  margin: 0 10px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

.App__header {
  text-align: center;
  font-size: 36px;
  color: #2f2f2f;
}

.App__subheader {
  margin: 16px 0;
  text-align: center;
  font-size: 14px;
  color: #666;
}